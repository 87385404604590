<template>
  <div class="container">
    <GeneralTable
      ref="GeneralTable"
      :column="column"
      tableName="jxGeneralManagementList"
      :option="option"
      :dataList="dataList"
      :totalNum="totalNum"
      @search-change="getList"
      @export-data="exportData"
      @import-click="isImport = true"
      @del-change="handleDel"
    >
      <!-- 统计插槽 -->
      <template slot="statistics">
        <statistics-box :statisticsList="statisticsList"
          v-loading="statisticsLoading" />
      </template>
      <template slot="operate-left">
        <el-button type="primary"
          size="mini"
          @click="handleAllocationCustomer()">批量调整手续费</el-button>
      </template>
      <template slot="status" slot-scope="{ item }">
        <el-tag type="success" v-if="item.status">启用</el-tag>
        <el-tag type="danger" v-else>禁用</el-tag>
      </template>
      <template slot="category" slot-scope="{ item }">
        {{ item.category == 1 ? '工程机械险' : item.category == 2 ? '车险' : '非车险' }}
      </template>
      <template slot="operate" slot-scope="{ item }">
        <el-button
          class="polecy-button"
          @click.native.prevent="handleAllocationCustomer([item])"
          type="text"
          size="small"
          >调整手续费</el-button
        >
      </template>
    </GeneralTable>
    <AddRegulationDialog
      v-model="isAddVisible"
      :editDetail="editData"
      @changeList="changeGetList"
    />
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import StatisticsBox from "@/components/StatisticsBox/index.vue";
import AddRegulationDialog from "@/views/routerPerformanceManagement/components/generalManagementList/components/AddRegulationDialog.vue";
import {
  performanceRemoveByIds,
  dictionaryBatch , performanceDownload, adjustPerformance, performanceRecordPageTitle, performancePage,outOrderTypeList, agentSelectList, salesmanSelectList
} from "@/api/policy";
import {
  pageProjectList,
  removeBatchById,
  listApplyDownload,
} from "@/api/project";
import {
  getDictList, //获取产品列表
  getDictType, //获取设备列表
} from "@/api/getManagementData.js";
import dayjs from "dayjs";
export default {
  name: "ProjectManagementList",
  components: { GeneralTable, AddRegulationDialog, StatisticsBox },
  data() {
    return {
      dataList: [],
      totalNum: 0,
      option: {
        isAdd: false,
        isRefresh: true, // 刷新 @search-change
        isSearch: true,
        isExport: true, // 导入 @import-click
        disFuzzySearch: true,
        pageSize: 10,
        searchList: [
          {
            label: "部门",
            prop: "salesmanDeptName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "保险公司",
            prop: "primaryInsuranceCompanyName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "投保日期",
            prop: "insuranceOfDate",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["insuranceStartDate", "insuranceEndDate"],
          },
          {
            label: "险种类型",
            prop: "category",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [
              { value: "", text: "全部" },
              { value: 1, text: "工程机械" },
              { value: 2, text: "车险" },
              { value: 3, text: "非车险" },
            ],
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "项目",
            prop: "performanceTypeName",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [],
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "细项",
            prop: "projectName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),performanceType
          },
          {
            label: "业务员",
            prop: "salesmanName",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [],
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "代理人",
            prop: "agentName",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [],
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "出单类型",
            prop: "insertType",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            selectList: [],
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "绩效生成时间",
            prop: "createTime",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["createStartTime", "createEndTime"],
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "绩效编号",
          prop: "serialNo",
          isShow: true,
        },
        {
          label: "保单号",
          prop: "policyNo",
          isShow: true,
        },
        {
          label: "保险公司",
          prop: "primaryInsuranceCompanyName",
          isShow: true,
        },
        {
          label: "分支机构",
          prop: "primaryInsuranceBranchName",
          isShow: true,
        },
        {
          label: "投保日期",
          prop: "insuranceOfDate",
          isShow: true,
        },
        {
          label: "险种类型",
          prop: "category",
          isShow: true,
          isSlot: true
        },
        {
          label: "项目",
          prop: "performanceTypeName",
          isShow: true,
        },
        {
          label: "细项",
          prop: "projectName",
          isShow: true,
        },
        {
          label: "业务员",
          prop: "salesmanName",
          isShow: true,
        },
        {
          label: "代理人",
          prop: "agentName",
          isShow: true,
        },
        {
          label: "部门",
          prop: "salesmanDeptName",
          isShow: true,
        },
        {
          label: "保费",
          prop: "totalPremium",
          isShow: true,
        },
        {
          label: "绩效类型",
          prop: "insertType",
          isShow: true,
        },
        {
          label: "代理商返点",
          prop: "agentKick",
          isShow: true,
        },
        {
          label: "代理商费用",
          prop: "agentAmount",
          isShow: true,
        },
        {
          label: "佣金绩效费率",
          prop: "brokerage",
          isShow: true,
        },
        {
          label: "佣金金额",
          prop: "brokerageAmount",
          isShow: true,
        },
        {
          label: "其他绩效费率",
          prop: "otherBrokerage",
          isShow: true,
        },
        {
          label: "其他金额",
          prop: "otherBrokerageAmount",
          isShow: true,
        },
        {
          label: "合计金额",
          prop: "sumAmount",
          isShow: true,
        },
        {
          label: "绩效生成时间",
          prop: "createTime",
          isShow: true,
        },
        {
          label: "绩效更新时间",
          prop: "updateTime",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 180,
          fixed: "right",
          isSlot: true,
        },
      ],
      typesOfConstructionMachineryList: [],
      brandInfoPageList: [],
      partBrandList: [],
      deviceTypeList: [],
      isExpansion: false,
      isImport: false,
      isAddVisible: false,
      editData: null,
      oldSearchData: {},
      operateTitle: "添加",
      machineryEquipmentTypeList: [],
      machineryProductTypeList: [],
      ruleForm: {
      },
      statisticsList: [
        {
          name: '保单总数',
          value: 0
        },
        {
          name: '保单总保费',
          value: 0
        },
        {
          name: '代理商费用',
          value: 0
        },
        {
          name: '佣金金额',
          value: 0
        },
        {
          name: '其他金额',
          value: 0
        },
        {
          name: '合计金额',
          value: 0
        },
      ],
      statisticsLoading: false,
    };
  },
  created() {
    outOrderTypeList().then((res) => {
      this.option.searchList.find((item) => {
        if (item.prop === "insertType") {
          item.selectList = res.data.map((code) => {
            return {
              text: code.type,
              value: code.type,
            };
          });
        }
      });
    });
    agentSelectList().then((res) => {
      this.option.searchList.find((item) => {
        if (item.prop === "agentName") {
          item.selectList = res.data.map((code) => {
            return {
              text: code.userName,
              value: code.userName,
            };
          });
        }
      });
    });
    salesmanSelectList().then((res) => {
      this.option.searchList.find((item) => {
        if (item.prop === "salesmanName") {
          item.selectList = res.data.map((code) => {
            return {
              text: code.userName,
              value: code.userName,
            };
          });
        }
      });
    });
    dictionaryBatch({ codes: "performanceType" }).then((res) => {
      this.option.searchList.find((item) => {
        if (item.prop === "performanceTypeName") {
          item.selectList = res.data.performanceType.map((code) => {
            return {
              text: code.dictValue,
              value: code.dictValue,
            };
          });
        }
      });
    });
    // performancePlanPage({
    //   pageIndex: 1,
    //   pageSize: 9999
    // }).then((res) => {
    //   this.option.searchList.find((item) => {
    //     if (item.prop === "programmeName") {
    //       console.log(res.data);
    //       // item.selectList = res.data.records.map((code) => {
    //       //   return {
    //       //     text: code.dictValue,
    //       //     value: code.dictValue,
    //       //   };
    //       // });
    //     }
    //   });
    //   //console.log(res);
    // });
  },
  computed: {},
  methods: {
    getList(v, pageNum, pageSize, n, type) {
      if (v.insuranceStartDate) {
        v.insuranceStartDate =
          dayjs(v.insuranceStartDate).format("YYYY-MM-DD") + " 00:00:00";
        v.insuranceEndDate =
          dayjs(v.insuranceEndDate).format("YYYY-MM-DD") + " 23:59:59";
      }
      if (v.createStartTime) {
        v.createStartTime =
          dayjs(v.createStartTime).format("YYYY-MM-DD") + " 00:00:00";
        v.createEndTime = dayjs(v.createEndTime).format("YYYY-MM-DD") + " 23:59:59";
      }
      // , deviceType: this.deviceTypeList.toString()
      // , partBrand: this.partBrandList.toString() 
      this.oldSearchData = Object.assign(
        { ...this.ruleForm },
        {
          ...v,
          pageIndex: pageNum,
          pageSize: pageSize,
          fuzzyLookupField: v.keyword,
        }
      );
      performancePage(this.oldSearchData).then((res) => {
        this.dataList = res.data.records || [];
        this.totalNum = res.data.total;
      }).catch((err) =>{
        this.dataList = [];
        this.totalNum = 0;
      });
      this.statisticsLoading = true;
      performanceRecordPageTitle(this.oldSearchData).then((res) => {
        this.statisticsLoading = false;
        this.statisticsList[0].value = res.data.policyCount || 0;
        this.statisticsList[1].value = res.data.policySumPremium || 0;
        this.statisticsList[2].value = res.data.agentSumPremium || 0;
        this.statisticsList[3].value = res.data.brokerageSumPremium || 0;
        this.statisticsList[4].value = res.data.otherSumPremium || 0;
        this.statisticsList[5].value = res.data.sumAmount || 0;
      }).catch(err => {
        this.statisticsLoading = false;
      });;
    },
    handleClassification(e, type) {
      // this[type].includes(e) ? this[type].splice(this[type].findIndex ,1) : this[type].push(e);
      this[type].includes(e) ? (this[type] = []) : (this[type] = [e]);
      this.changeGetList();
    },
    changeGetList() {
      this.$refs.GeneralTable.getList();
    },
    goDetail(e) {
      console.log("详情");
      this.$router.push({
        path: "/routerProjectManagement/projectDetails?id=" + e.id,
      });
    },
    exportData(e) {
      performanceDownload({
        ...this.oldSearchData,
      });
    },
    handleDel(e) {
      if (!e || !e.length) {
        this.$message.error('请选择需要删除的员工！')
        return false;
      }
      this.$confirm("删除后该员工当月的绩效会清除，是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = e.map((val) => val.id).toString();
        performanceRemoveByIds(ids).then((res) => {
          this.changeGetList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    editDetail(e) {
      // this.isAddVisible = true;
      // if (e && e.id) {
      //   this.editData = e;
      // }else {
      //   this.editData = null;
      // }
      this.$router.push({
        path: `//routerPerformanceManagement/staffDetails?id=${e.id}&year=${dayjs(e.yearMonth).format("YYYY")}&month=${dayjs(e.yearMonth).format("MM")}`,
      });
    },
    // 获取设备类型列表
    getMachineryEquipmentTypeList() {
      getDictType({
        dictType: this.machineryProductTypeList.find(
          (val) => val.dictName == this.ruleForm.deviceType
        ).dictType,
      })
        .then((res) => {
          if (res.flag === 1) {
            this.machineryEquipmentTypeList = res.obj;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择产品类型
    selectMachineryProductType() {
      this.ruleForm.deviceCategory = "";
      this.getMachineryEquipmentTypeList();
    },
    handleAllocationCustomer(e) {
      if (!e) {
        let customerList = this.$refs.GeneralTable.tableSelectionList;
        if (customerList.length) {
          this.editData = customerList;
          this.isAddVisible = true;
        } else {
          this.$message.error('请选择绩效');
        }
        return false;
      }
      this.editData = e;
      this.isAddVisible = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.search-filter {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  .product,
  .brand {
    display: flex;
    .label {
      width: 100px;
      height: 28px;
      line-height: 28px;
      white-space: nowrap;
    }
    .list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .item {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        padding: 0 12px;
        border: 1px solid #4278c9;
        margin: 0 15px 15px 0;
        cursor: pointer;
        border-radius: 4px;
        // &:hover {
        //   color: #4278c9;
        //   background-color: #eff6ff;
        // }
      }
      .check-item {
        color: #ffffff;
        background-color: #4278c9;
        border: 1px solid #4278c9;
      }
    }
  }
  .expansion-box {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    cursor: pointer;
    font-size: 14px;
    color: #0080ff;
    text-align: center;
  }
}
.enableStatus1 {
}
.enableStatus2 {
}
.slot-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 250px;
    padding: 10px;
    border: 1px solid #dee3e6;
    border-radius: 6px;
    margin: 0 15px 15px 0;
    .img {
      width: 230px;
      height: 230px;
      margin-bottom: 8px;
    }
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .modelNum {
        font-size: 14px;
      }
      .price {
        font-size: 18px;
        color: #fda835;
        font-weight: bold;
      }
    }
    .tags {
      display: flex;
      align-items: center;
      .tag {
        margin-right: 8px;
        padding: 5px 8px;
        background-color: #f2f7fd;
        color: #3470ff;
        border-radius: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
